define(['app'], function (app) {

  const productBuyNowPayLaterProviders = function () {
    const component = {};

    const _config = {
      selectors: {
        component: '[data-component=productBuyNowPayLaterProviders]',
        learnMoreButton: '[id=newpay-learn-more]',
        newpayProviderInfo: '[id=newpay-provider-info]',
      },
      subscribeChannels: {
        BNPLProvidersInfo: 'productBuyNowPayLaterProviders/providersInfo'
      },
      dataAttributes: {
        isDesktop: 'data-isDesktop',
        productPrice: 'data-productPrice'
      }
    };

    const _init = function (element, userDriven, isDesktop, productPrice) {
      component.element = element;

      if (isDesktop) {
        component.isDesktop = isDesktop;
      } else {
        component.isDesktop = component.element.getAttribute(component.config.dataAttributes.isDesktop);
      }

      if (productPrice) {
        component.productPrice = productPrice;
      } else {
        component.productPrice = component.element.getAttribute(component.config.dataAttributes.productPrice);
      }

      if (!userDriven) {
        app.subscribe(component.config.subscribeChannels.BNPLProvidersInfo, component.getNewProvidersInfo);
      }

      component.addNewpayEventListener();

      return component;
    };

    const _getNewProvidersInfo = function (productId) {
      let url = '/' + productId + '.productBNPL' + '?isDesktop=' + component.isDesktop;

      if (new URLSearchParams(window.location.search).get('displayBuyNowPayLaterProviders') === 'true')
        url = url + '&displayBuyNowPayLaterProviders=true';

      app.ajax.get({
        url: url,
        success: component.successHandler,
        error: component.errorHandler
      });
    };

    const _getNewpayInitToken = function () {
      component.addScript('https://static.thcdn.com/design-assets/scripts/dekopay/2022-10-19-index.js').then(() => {
        app.ajax.get({
          url: '/newpay/initToken.newpay',
          requestHeader: {
            header: 'Content-Type',
            value: 'application/json'
          },
          success: component.newpaySuccessHandler,
          error: component.newpayErrorHandler
        });
      })
    };

    const _addNewpayEventListener = function () {
      component.learnMoreButton = component.element.querySelector(component.config.selectors.learnMoreButton);

      if (component.learnMoreButton) {
        if (component.checkCookieExists('DekoEligibilityCheckStatus')
          && (component.getCookieValue('DekoEligibilityCheckStatus') === 'PREQUALIFICATION_ACCEPT')
          && !component.newpayPreviouslyUsed) {
          component.newpayPreviouslyUsed = true;
          component.getNewpayInitToken();
        }

        component.learnMoreButton.addEventListener('click', (event) => {
          event.preventDefault();
          if (component.wallet) {
            component.wallet.promotionalInfo(component.productPrice * 100);
          } else {
            component.getNewpayInitToken();
          }
        });
      }
    }

    const _openNewpaySlider = function (initToken) {
      if (initToken && window.dekoWallet) {
        component.wallet = window.dekoWallet.init(() => Promise.resolve(initToken));

        const productPriceInPence = component.productPrice * 100;

        if (component.wallet) {
          component.wallet.promotionalInfo(productPriceInPence)

          window.addEventListener('message', (m) => {
            if (m.data.message === 'PREQUALIFICATION_ACCEPT') {
              component.addNewpayEligibleCheck(productPriceInPence, initToken, true);
            }
          });
        }
      }
    }

    const _successHandler = function (response) {
      let parent = component.element.parentNode;
      parent.innerHTML = response;
      let element = parent.querySelector(component.config.selectors.component);
      component.init(element, true, component.isDesktop, component.productPrice);
    };

    const _newpaySuccessHandler = function (response) {
      let parsedResponse = JSON.parse(response)

      if (component.newpayPreviouslyUsed && !component.addedEligibleCheck) {
        component.addedEligibleCheck = true;
        const productPriceInPence = component.productPrice * 100;
        component.addNewpayEligibleCheck(productPriceInPence, parsedResponse, false);
      } else {
        component.openNewpaySlider(parsedResponse);
      }
    };

    const _addScript = function (src) {
      if (!document.querySelector(`script[src='${src}']`)) {
        return new Promise((resolve, reject) => {
          const createNewpayScriptTag = (src) => {
            const scriptTag = document.createElement('script');
            scriptTag.src = src;
            scriptTag.defer = true;
            scriptTag.onload = resolve;
            scriptTag.onerror = reject;
            return scriptTag;
          }

          document.body.appendChild(createNewpayScriptTag(src));
        });
      }

      return Promise.resolve();
    }

    const _errorHandler = function () {
      console.error('ERROR: Could not retrieve new BNPL info');
    };

    const _newpayErrorHandler = function () {
      console.error('ERROR: Could not retrieve newpay init token');
    };

    const _addNewpayEligibleCheck = function (productPriceInPence, parsedResponse, shouldDefault) {
      const getNewpayFinanceInfo = async (value, initToken) => {
        return window.dekoWallet.financeInfo('revolving_credit', {value, initToken}, 'v2.0');
      };

      const createEligibleComponent = (tagLine, logo) => {
        component.newpayProviderInfo = component.element.querySelector(component.config.selectors.newpayProviderInfo);

        if (component.newpayProviderInfo) {
          component.newpayProviderInfo.innerHTML = '';

          const tick = document.createElement('img');
          tick.src = logo;
          tick.alt = 'Newpay approval tick';
          tick.style['margin-right'] = '8px'
          tick.style['align-self'] = 'center';

          const text = document.createElement('span');
          text.innerText = tagLine;
          text.style['margin-right'] = '8px'

          const learnMoreWrapper = document.createElement('span');
          learnMoreWrapper.className = 'productBuyNowPayLater_provider_link'

          const learnMoreAnchor = document.createElement('button');
          learnMoreAnchor.id = 'newpay-learn-more';
          learnMoreAnchor.className = 'newpay-learn-more';

          const accessibleSpan = document.createElement('span');
          accessibleSpan['aria-hidden'] = 'true';
          accessibleSpan.style.position = 'absolute';
          accessibleSpan.style.left = '-10000px';
          accessibleSpan.style.top = 'auto';
          accessibleSpan.style.overflow = 'hidden';
          accessibleSpan.innerText = 'Learn more about newpay';

          const learnMoreText = document.createElement('span');
          learnMoreText.className = 'productBuyNowPayLater_provider_link_text';
          learnMoreText['aria-label'] = 'Learn more about newpay';
          learnMoreText.innerText = 'Learn more';

          learnMoreAnchor.appendChild(accessibleSpan);
          learnMoreAnchor.appendChild(learnMoreText);
          learnMoreWrapper.appendChild(learnMoreAnchor);

          const wrapper = document.createElement('p');
          wrapper.className = 'productBuyNowPayLater_provider_text'
          wrapper.style.display = 'flex';
          wrapper.style['align-items'] = 'baseline';
          wrapper.style['flex-wrap'] = 'wrap';
          wrapper.appendChild(tick);
          wrapper.appendChild(text);
          wrapper.appendChild(learnMoreWrapper);

          component.newpayProviderInfo.appendChild(wrapper);

          component.addNewpayEventListener();

          if (component.checkCookieExists('DekoEligibilityCheckStatus')
            && (component.getTimesCookieSet('DekoEligibilityCheckStatus') < 2)) {
            component.setCookie('DekoEligibilityCheckStatus',
              component.getCookieValue('DekoEligibilityCheckStatus'))
          }

          if (component.checkCookieExists('DekoEligibilityCreditLimit')
            && (component.getTimesCookieSet('DekoEligibilityCreditLimit') < 2)) {
            component.setCookie('DekoEligibilityCreditLimit',
              component.getCookieValue('DekoEligibilityCreditLimit'))
          }
        }
      }

      if (window.dekoWallet) {
        getNewpayFinanceInfo(productPriceInPence, parsedResponse).then(data => {
          if (data.eligibleTagline && data.financeEligibleLogo) {
            createEligibleComponent(data.eligibleTagline, data.financeEligibleLogo);
          } else if (shouldDefault) {
            createEligibleComponent('Eligibility check successful',
              'https://assets.dekopay.com/eligibility-success.png');
          }
        })
      }
    }

    const _setCookie = function _setCookie(name, value) {
      var path = '/'; // it has to be root for the cookie to be visible on the entire website
      var now = new Date();
      now.setTime(now.getTime() + 4 * 3600 * 1000);

      document.cookie = name + '=' + value + '; expires=' + now.toUTCString() + '; path=' + path;
    };

    const _checkCookieExists = function _checkCookieExists(name) {
      const cookieName = name + '=';
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(cookieName) === 0) {
          return true;
        }
      }
      return false;
    };

    const _getTimesCookieSet = function _getTimesCookieSet(name) {
      const cookieArray = document.cookie.includes(name) && document.cookie.split('; ')
        .filter(cookie => cookie.startsWith(name));

      return cookieArray && cookieArray.length ? cookieArray.length : 0;
    }

    const _getCookieValue = function _getCookieValue(name) {
      let cookieValue = ''

      if (document.cookie.includes(name)) {
        cookieValue = document.cookie.split('; ')
          .find((cookie) => cookie.startsWith(name)).split('=')[1]
      }

      return cookieValue;
    };

    component.init = _init;
    component.config = _config;
    component.getNewProvidersInfo = _getNewProvidersInfo;
    component.successHandler = _successHandler;
    component.newpaySuccessHandler = _newpaySuccessHandler;
    component.newpayErrorHandler = _newpayErrorHandler;
    component.errorHandler = _errorHandler;
    component.getNewpayInitToken = _getNewpayInitToken;
    component.addNewpayEligibleCheck = _addNewpayEligibleCheck;
    component.addScript = _addScript;
    component.openNewpaySlider = _openNewpaySlider;
    component.addNewpayEventListener = _addNewpayEventListener;
    component.checkCookieExists = _checkCookieExists;
    component.setCookie = _setCookie;
    component.getCookieValue = _getCookieValue;
    component.getTimesCookieSet = _getTimesCookieSet;
    return component;
  };

  return productBuyNowPayLaterProviders;
});
